import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { initializeApp } from "firebase/app";
require("dotenv").config();
import "@/assets/page-loader.css";
import VueQuillEditor from "vue-quill-editor";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.config.productionTip = false;
Vue.use(VueQuillEditor);

Vue.filter("formatDate", function (value, formatString) {
	if (!value) return ""; // Проверьте наличие значения
	const date = typeof value === "string" ? parseISO(value) : value;
	return format(date, formatString);
});
const firebaseConfig = {
	apiKey: "AIzaSyCUHBRTSpqgL_tqclDpAzqH_jSDpAOalHg",
	authDomain: "rst-ozo-adminpanel.firebaseapp.com",
	projectId: "rst-ozo-adminpanel",
	storageBucket: "rst-ozo-adminpanel.appspot.com",
	messagingSenderId: "49506918405",
	appId: "1:49506918405:web:3a35723462ba0630107c6c",
	measurementId: "G-WZLTHPQVFT",
};
const app = initializeApp(firebaseConfig);
axios.interceptors.response.use(
	(response) => response,
	(error) => {
		// Если код состояния равен 401 (Unauthorized), выполняем редирект
		if (error.response && error.response.status === 401) {
			store.dispatch("emptyUser");
			router.push("/login"); // Замените '/login' на путь к странице входа
		}

		return Promise.reject(error);
	}
);
// axios.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.error('Axios interceptor error:', error);

//     if (error.response) {
//       // Ответ от сервера с данными
//       console.error('Response data:', error.response.data);
//       // Статус ответа от сервера
//       console.error('Response status:', error.response.status);
//       // Заголовки ответа от сервера
//       console.error('Response headers:', error.response.headers);
//     } else if (error.request) {
//       // Запрос был сделан, но ответ не был получен
//       console.error('Request made but no response received:', error.request);
//     } else {
//       // Что-то случилось в ходе настройки запроса
//       console.error('Error setting up request:', error.message);
//     }
//     return Promise.reject(error);
//   }
// );
axios.defaults.baseURL = "https://api.volantime.kz";
// axios.defaults.baseURL = "https://api.volantime.dragau.kz";

axios.defaults.withCredentials = true;
axios.interceptors.request.use((config) => {
	config.headers.appname = "admin.rst.ozo.direct";
	if (store.getters.user.token)
		config.headers.Authorization = `Bearer ${store.getters.user.token}`;
	config.headers["Content-Type"] = "application/json";
	config.headers["Access-Control-Allow-Origin"] = "*";
	config.headers["Access-Control-Allow-Methods"] =
		"GET, POST, PUT, DELETE, OPTIONS";
	config.headers["Access-Control-Allow-Headers"] =
		"Origin, X-Requested-With, Content-Type, Accept, Authorization";
	return config;
});
// test
Vue.prototype.$http = axios;
Vue.prototype.$store = store;
Vue.prototype.$firebase = app;
Vue.prototype.appname = "Админ панель";

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
