import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const user = store.getters.user;

const routes = [
	// {
	// 	path: "/admin",
	// 	name: "Main",
	// 	component: () => import("../views/index.vue"),
	// },
	// {

	{
		path: "/",
		redirect: "/Results",
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("../views/Login.vue"),
	},

	{
		path: "/Profile",
		name: "Profile",
		component: () => import("../views/Profile"),
	},

	{
		path: "/Staff",
		name: "Staff",
		component: () => import("../views/Staff"),
	},

	{
		path: "/ProductList",
		name: "ProductList",
		component: () => import("../views/ProductList.vue"),
	},
	{
		path: "/Players",
		name: "Players",
		component: () => import("../views/Players.vue"),
	},
	{
		path: "/CreateProduct",
		name: "CreateProduct",
		component: () => import("../views/CreateProduct"),
	},
	{
		path: "/error",
		name: "ErrorPage",
		component: () => import("../views/ErrorPage"),
	},
	{
		path: "/Results",
		name: "Results",
		component: () => import("../views/Results"),
	},
	{
		path: "/result-body/:resultId",
		name: "ResultBody",
		component: () => import("../views/ResultBody"),
	},
	{
		path: "/category-list",
		name: "Category",
		component: () => import("../views/Category"),
	},
	{
		path: "/post-list",
		name: "PostList",
		component: () => import("../views/PostList"),
	},
	{
		path: "/post/:postId",
		name: "PostBody",
		component: () => import("../views/PostBody"),
	},
	{
		path: "/CreatePost",
		name: "CreatePost",
		component: () => import("../views/CreatePost"),
	},
	{
		path: "/product-body/:productId",
		name: "ProductBody",
		component: () => import("../views/ProductBody"),
	},
	{
		path: "/player-body/:playerId",
		name: "PlayerBody",
		component: () => import("../views/PlayerBody"),
	},
	{
		path: "/catalog",
		name: "Catalog",
		component: () => import("../views/Catalog"),
	},
	{
		path: "*", // Этот маршрут будет срабатывать для всех несуществующих маршрутов
		name: "NotFound",
		component: () => import("../views/notFound"),
	},
];

const router = new VueRouter({
	mode: "history", // Устанавливаем режим "history"
	base: "/",
	routes,
});

router.beforeEach(async (to, from, next) => {
	// if (!store.state.user.logged) { // Пользователь не залогинен или нет актуальных токенов
	//   next({name: "Login"}); // Попытка загрузки пользователя
	// } // Попытка загрузки пользователя
	if (to.name !== "Login" && !user.logged) {
		next({ name: "Login" }); // Пользователь не залогинен - перенаправляем на страницу авторизации
	} else {
		next(); // Все в порядке, продолжаем нормальный переход на запрошенную страницу
	}
});
// router.beforeEach(async (to, from, next) => {
//   if (!user.logged) {
//     // Если пользователь не аутентифицирован, попробуем выполнить автоматическую авторизацию
//     try {
//       await store.dispatch("fetchUserFromFirebase"); // Асинхронный вызов для авторизации
//       next(); // После успешной авторизации, продолжаем далее
//     } catch (error) {
//       // Если авторизация не удалась, перенаправляем на страницу логина
//       next({ name: "Login" });
//     }
//   } else {
//     next(); // Если пользователь уже аутентифицирован, продолжаем далее
//   }
export default router;
